export function buildUniqueSessionLink(sessionId, callType) {
  const env = String(process.env.REACT_APP_ENV)

  let base = 'https://beta.techinterviewer.ai'
  if (env === 'staging') {
    base = 'https://staging.techinterviewer.ai'
  }
  if (env === 'development') {
    base = 'http://localhost:3001'
  }

  if (callType === 'phone') {
    base += '/phone'
  }

  return base + '?session=' + sessionId
}
