import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Drawer, Avatar, Box, CircularProgress } from "@mui/material";
import { MenuList, MenuItem, List, ListItem, IconButton, TextField } from "@mui/material";
import { Popper, Grow, Paper, ClickAwayListener } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { Add, Circle, UnfoldMore, VideocamOutlined } from "@mui/icons-material";

import Scrollbars from "react-custom-scrollbars";

import api from "../../api/api";

import WayfasterLogo from "../../wayfaster-logo-w.svg";

import Switcher from "../team/Switcher";

const SECTIONS_OPTIONS = ["Introduction", "Background"];

export const Sidebar = ({
  position,
  sx,
  user,
  isAuthenticated,
  isLoading,
  importedInterviews,
  onNavigate=()=>{},
  onPreviousInterviewSet=()=>{},
  onNextInterviewSet=()=>{},
}) => {
  const { interview_id,
    session_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  const [currentTeam, setCurrentTeam] = useState({});
  const [teams, setTeams] = useState([]);

  const [accountOpen, setAccountOpen] = useState(false);
  const accountRef = useRef(null);


  const [interviews, setInterviews] = useState(
    importedInterviews ? importedInterviews : []
  );

  const [interview, setInterview] = useState({});
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false);
  const [getInterviewsReturned, setGetInterviewsReturned] = useState(false);
  const [sections, setSections] = useState(SECTIONS_OPTIONS);
  
  const [interviewAccordionOpen, setInterviewAccordionOpen] = useState(true);

  const [globalOverrideUser, setGlobalOverrideUser] = useState("");

  useEffect(() => {
    const overrideUserLocal = localStorage.getItem("globalOverrideUser");
    if (
      globalOverrideUser !== undefined &&
      globalOverrideUser.length === 0 &&
      overrideUserLocal != null
    ) {
      setGlobalOverrideUser(overrideUserLocal);
    }
  }, [globalOverrideUser, setGlobalOverrideUser]);

  useEffect(() => {
    if (!importedInterviews && user && !getInterviewsCalled) {
      var userId = user.email;
      const overrideUserLocal = localStorage.getItem("globalOverrideUser");
      if (overrideUserLocal != null) {
        userId = overrideUserLocal;
      }
      const teamId = localStorage.getItem("teamId");
      if (!teamId) {
        navigate("/");
        return;
      }
      setGetInterviewsCalled(true);
      api
        .getInterviews(userId, teamId)
        .then((response) => {
          setInterviews(
            response.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )
          );
          setGetInterviewsReturned(true);

          const interviewIds = response.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ).map(
            (interview) => interview._id
          );

          const interview_idx = interviewIds.indexOf(interview_id);
          if (interview_idx === -1) {
            console.log("interview doesn't exist");
            return;
          }

          if (interview_idx > 0) {
            onPreviousInterviewSet(interviewIds[interview_idx - 1]);
          } else {
            onPreviousInterviewSet(null);
          }

          if (interview_idx < interviewIds.length - 1) {
            onNextInterviewSet(interviewIds[interview_idx + 1]);
          } else {
            onNextInterviewSet(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setGetInterviewsCalled(false);
          setGetInterviewsReturned(true);
          return;
        });
    }
  }, [
    interview,
    importedInterviews,
    getInterviewsCalled,
    interview_id,
    navigate,
    refreshSections,
    user,
    onPreviousInterviewSet,
    onNextInterviewSet,
  ]);

  const thumbVDark = ({ style, ...props }) => (
    <div style={{ ...style, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', cursor: 'pointer' }} {...props} />
  );

  function refreshSections(interview) {
    const foundSections = interview.questions
      .reduce((acc, question) => {
        if (!acc.includes(question.section)) acc.push(question.section);
        return acc;
      }, [])
      .sort(sectionSort);
    setSections(foundSections);
  }

  function interviewSort(a, b) {
    // top_val comes first
    if (a.type && a.type == 'template') return 1;
    if (b.type && b.type == 'template') return -1;
    // neither matches `value` - compare them normally
    else return 0;
  }


  function sectionSort(a, b) {
    const top_val = "Introduction";
    const null_val = "undefined";

    // top_val comes first
    if (a == top_val) return -1;
    if (b == top_val) return 1;

    // nulls sort after anything else
    if (a === null_val) {
      return 1;
    }
    if (b === null_val) {
      return -1;
    }

    // neither matches `value` - compare them normally
    return a.localeCompare(b);
  }

  const assumeUser = () => {
    localStorage.setItem("globalOverrideUser", globalOverrideUser);
    alert("set. now refresh the page");
  };

  const handleAccountToggle = () => {
    setAccountOpen((prevOpen) => !prevOpen);
  };

  const handleAccountClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setAccountOpen(false);
  };

  const handleOnCopy = (interviewConfigId) => {
    console.log("handleOnCopy", interviewConfigId);
    navigate(`/create-interview?copy_from=${interviewConfigId}`);
  };


  const teamClicked = (teamId) => {
    if (currentTeam._id.$oid !== teamId) {
      const new_team = teams.find((t) => t._id.$oid === teamId);
      localStorage.setItem('teamId', new_team._id.$oid);
      setCurrentTeam(new_team);
      window.location.reload();
    }
  };

  // auto re-direct if no team found in teams list
  useEffect(() => {
    if (user && !isLoading) {
      var userId = user.email;
      const globalOverrideUserLocal =
        localStorage.getItem("globalOverrideUser");
      if (globalOverrideUserLocal) {
        userId = globalOverrideUserLocal;
      }

      // ping DB for user-specific team if exists.
      // backend logic: if user-specific team does not exist, check if there is a team associated with the user and return that
      // if nothing exists, take them to team creation onboarding flow
      let teamId = localStorage.getItem("teamId");
      if (teamId && Object.keys(currentTeam).length === 0) {
        api
          .getTeam({ userId, teamId })
          .then((res) => {
            const team = res.data;
            setCurrentTeam(team);

            api.getTeams({ userId }).then((response) => {
              const fetchedTeams = response.data;
              setTeams(fetchedTeams);
            });
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem("teamId");

            api
              .getTeams({ userId })
              .then((response) => {
                const fetchedTeams = response.data;
                setTeams(fetchedTeams);
                if (fetchedTeams.length === 0) {
                  navigate("/your-team");
                }

                let invited_team_id = null;
                fetchedTeams.map((team) => {
                  team.members.map((member) => {
                    if (
                      member.user_id === userId &&
                      member.status === "invited"
                    ) {
                      invited_team_id = team._id.$oid;
                      return;
                    }
                  });
                  return;
                });

                if (invited_team_id) {
                  localStorage.setItem("teamId", invited_team_id);
                  navigate("/your-team");
                }

                console.log("no invites");

                // no invites found. check if active membership on any
                let active_team_id = null;
                fetchedTeams.map((team) => {
                  team.members.map((member) => {
                    if (
                      member.user_id === userId &&
                      member.status === "active"
                    ) {
                      active_team_id = team._id.$oid;
                      setCurrentTeam(team);
                      localStorage.setItem("teamId", active_team_id);
                      console.log(
                        "active",
                        active_team_id,
                        localStorage.getItem("teamId")
                      );
                      return;
                    }
                  });
                });

                if (active_team_id) {
                  console.log("found active");
                  window.location.href = "/";
                }
              })
              .catch((error) => {
                console.log(error);
                // no teams found with user
                if (error.response && error.response.status == 400) {
                  navigate("/your-team");
                }
              });
          });
      }
    }
  }, [user, isLoading, setTeams, setCurrentTeam, teams, currentTeam]);

  const resetGlobalOverrideUser = () => {
    setGlobalOverrideUser('')
    localStorage.removeItem('globalOverrideUser')
    localStorage.removeItem('teamId')
    window.location.reload();
  }

  return (
    <Drawer
    variant="permanent"
    sx={{
      width: 312,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        backgroundColor: 'var(--color-dark-mode)', width: 312, zIndex: "var(--zIndex-canvas)" },
      }}
    >
      <Box className="d-flex flex-col" style={{ padding: "0", height: '100%' }}>
        <button
          className="btn-main btn-dark-mode btn-logo d-flex"
          style={{ height: "fit-content", backgroundColor: 'var(--color-dark-mode)', padding: '8px 12px' }}
          aria-label="menu"
          onClick={() => {
            if (location.pathname != "/your-team") navigate("/interviews");
          }}
        >
          <img className="mr-auto" alt="Wayfaster" style={{ height: '32px' }} src={WayfasterLogo} />
        </button>
          {/* Team Switcher */}
          <div style={{ marginRight: "12px", marginLeft: "12px", height: '56px' }}>
              {(currentTeam && currentTeam._id && currentTeam._id.$oid) ? <Switcher key={currentTeam._id.$oid}
              currentTeam={currentTeam}
              teams={teams}
              popper={true}
              clicked={teamClicked}
              ></Switcher> : <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
          </div>

          {/* Heading - General */}
          <h4 className="font-inter" style={{ color: 'var(--color-dark-mode-light)', fontSize: '12px', fontWeight: 500, margin: '8px', padding: '0px 4px' }}>General</h4>
               
          {/* General Nav Items */}
          <div
            className={`d-flex flex-row clickable getting-started ${location.pathname === '/getting-started' ? 'selected' : ''}`}
            style={{
              margin: "0px 8px 8px", 
              padding: '12px', 
              borderRadius: '8px', 
              height: '48px',
              backgroundColor: location.pathname === '/getting-started' ? 'var(--color-hover-dark)' : 'var(--color-dark-mode)',
            }}
            onClick={() => {
              navigate('/getting-started');
            }}
            >
            <div className="d-flex align-items-center" style={{ width: '100%' }}>
              <VideocamOutlined sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }} />
              <h4 className="font-inter" style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff', fontWeight: 500 }}>Getting Started</h4>
            </div>
          </div>   

          {/* Heading - Interviews */}
          <h4 className="font-inter" style={{ color: 'var(--color-dark-mode-light)', fontSize: '12px', fontWeight: 500, margin: '12px 8px', padding: '0px 4px' }}>Interviews</h4>

          {/* Interviews Nav Item */}
          <>
            <div className="d-flex flex-row clickable" style={{ margin: "0px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: interview_id ? 'var(--color-hover-dark)' : 'transparent' }}
              onClick={() => setInterviewAccordionOpen((prev) => !prev)}>
              <PeopleOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PeopleOutlinedIcon>
              <h4 className="font-inter" style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff', fontWeight: 500 }}>Interviews</h4>
              <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: interviewAccordionOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
            </div>

            {/* Interviews List */}
            {!getInterviewsReturned && <CircularProgress size={20} className="mx-auto" sx={{ color: 'var(--color-primary)', marginTop: '16px' }}/>}
            {interviews.length > 0 &&
              <>
              <Scrollbars renderThumbVertical={thumbVDark} style={{ transition: 'all .3s ease', flex: interviewAccordionOpen ? interviews.length - 7: 0, scrollbarColor: 'rgba(255, 255, 255, 0.2) var(--color-dark-mode)' }}>
                <Box sx={{ overflow: 'hidden', padding: '0px 12px', boxShadow: 'inset -8px -12px 48px -8px var(--color-dark-mode)' }}>
                  <List style={{ paddingTop: '0px' }} className="interview-list-wrapper">
                  {interviews.length === 0 ?
                    <div style={{ margin: 'auto'  }}>
                      <p style={{ fontSize: '12px', color: 'var(--color-dark-mode-light)' }}>No interviews to show</p>
                    </div>
                    : interviews.sort(interviewSort).map((interview) => {
                    return (
                        <ListItem
                          className={interview_id === interview._id ? 'interview-list-item-selected' : "interview-list-item" }
                          style={{
                            padding: '4px 8px',
                            borderRadius: 0,
                            height: 'fit-content',
                            margin: '4px 0px',
                            width: '264px'
                          }}
                          onClick={() => {
                            if (interview_id !== interview._id) {
                              navigate('/interviews/' + interview._id);
                              onNavigate(interview._id);
                            }
                          }}
                        >
                          <div className="d-flex flex-row" style={{ width: '100%' }}>
                            <div className="d-flex" style={{ flex: 11 }}>
                              <p className="font-inter" style={{ lineHeight: '18px', fontSize: '14px', margin: '8px 4px', fontWeight: 400, color: interview_id === interview._id ? '#fff' :  'var(--color-dark-mode-light)'}}>
                                {interview.type && interview.type == 'template' && <Circle className="m-auto color-mid" style={{ fontSize: 8, marginRight: '4px' }}/>}
                                {interview.title}
                              </p>
                            </div>
                            <div className="d-flex" style={{ marginLeft: 'auto', flex: 1 }}>
                              <button className="btn-main small d-flex gray-dark outlined my-auto"
                                style={{ marginLeft: 'auto', width: '28px', height: '28px' }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleOnCopy(interview._id)}
                                }>
                                <ContentCopyOutlined className="m-auto" sx={{ fontSize: '18px', color: 'var(--color-dark-mode-light)' }}></ContentCopyOutlined>
                              </button>
                            </div>
                          </div>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              </Scrollbars>
              </>
            }
          </>

          {/* Settings Nav Item */}
          <div className="d-flex flex-row clickable" style={{ margin: "0px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: location.pathname === '/settings' ? 'var(--color-hover-dark)' : 'transparent', marginTop: '8px' }}
            onClick={() => navigate("/settings")}>
            <SettingsOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></SettingsOutlinedIcon>
            <h4 className="font-inter" style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff', fontWeight: 500 }}>Settings</h4>
          </div>

          {/* Comment out pre-vetted candidates for now */}
          {/* <div className="d-flex flex-row clickable" style={{ margin: "8px 12px 0px", padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-hover-dark)' }}
            onClick={() => {
              navigate('/candidates')
              // show prevetted on side
              // setPrevettedAccordionOpen((prev) => !prev)
            }}>
            <PersonSearchOutlinedIcon className="my-auto" sx={{ fontSize: '24px', marginRight: '8px', color: '#fff' }}></PersonSearchOutlinedIcon>
            <h4 style={{ lineHeight: '24px', fontSize: '14px', margin: 0, color: '#fff' }}>Pre-vetted Candidates</h4>
            <ExpandMoreIcon fontSize="14px" className="my-auto" style={{ transition: 'all .3s ease', marginLeft: 'auto', color: '#fff', transform: 'rotate(-90deg)' }} />
          </div> */}

          <Box style={{ flex: 3 }}>
            {/* placeholder */}
          </Box>
          
        {user && (user.email === 'varun@techinterviewer.ai' || user.email === 'shreyas@techinterviewer.ai' || user.email === 'shreyas.jaganmohan@gmail.com' || user.email == 'miguelpatricktaruc@gmail.com') &&
            <div className="d-flex flex-row" style={{ width: "100%", marginTop: '8px' }}>
              <TextField 
                variant="outlined"
                sx={{
                  margin: 'auto',
                  flex: 2,
                  '& .MuiOutlinedInput-input': {
                    height: '20px', 
                    padding: '8px 12px',
                    fontSize: '14px',
                    margin: 'auto'
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: 
                    { border: '1px solid var(--color-hover-dark)',
                    borderRadius: '6px', backgroundColor: 'var(--color-main)', color: 'white' }
                }}
              style={{ color: 'white', width: 'fit-content', fontSize: '14px', margin: '10px' }} 
              value={globalOverrideUser} onChange={(e) => {setGlobalOverrideUser(e.target.value)}} />
              <button className="btn-main btn-dark-mode my-auto" onClick={() => {assumeUser()}} 
                style={{ fontSize: '12px', flex: 1, height: '36px', marginRight: '8px' }}>
                <span className="font-inter" style={{ fontWeight: 400 }}>Assume</span>
              </button>
              <button className="btn-main btn-dark-mode my-auto" onClick={() => {resetGlobalOverrideUser()}} 
                style={{ fontSize: '12px', flex: 1, height: '36px', marginRight: '8px' }}>
                <span className="font-inter" style={{ fontWeight: 400 }}>Reset</span>
              </button>
            </div>
          }
        
        <button
          className="btn-main btn-dark-mode-sidebar clickable-grow d-flex"
          style={{
            margin: "8px 12px 0px",
            padding: '16px 12px',
            borderRadius: '8px',
            border: '1px solid #3B3B3B',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            boxSizing: 'border-box',
            outline: 'none', // Remove default focus outline
            transition: 'all .2s ease'
          }}
            onClick={() => {
              navigate('/create-interview')
            }}
          >
            <span className="font-inter" style={{ color: '#fff', fontSize: '14px', fontWeight: 500 }}>New Interview</span>
            <Add sx={{ color: '#fff', fontSize: '18px' }} />
        </button>     

        {user && isAuthenticated && (
          <>
            
            <div className="d-flex flex-row mx-auto" style={{ padding: '8px', width: '100%', marginTop: '12px' }}>
              <button
                className="btn-main btn-dark-mode d-flex flex-row"
                style={{ height: "40px", width: '100%' }}
                ref={accountRef}
                onClick={handleAccountToggle}
              >
                <Avatar
                  className="my-auto"
                  sx={{
                    bgcolor: "var(--color-primary)",
                    color: "black",
                    width: 32,
                    height: 32,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    marginTop: "-2px",
                    marginRight: "6px",
                  }}
                >
                  <span className="font-inter" style={{ fontWeight: 600 }}>{Array.from(user.email)[0]}</span>
                </Avatar>
                <h6
                  className="color-main my-auto font-inter"
                  style={{ marginRight: "4px", fontSize: "12px", color: "#fff", fontWeight: 500 }}
                >
                  {user.email}
                </h6>
                <UnfoldMore className="color-gray my-auto" style={{ marginLeft: 'auto', fontSize: '24px' }} />
              </button>
            </div>
          </>
        )}
      </Box>

      {/* Actions */}
      <Popper
        open={accountOpen}
        anchorEl={accountRef.current}
        role={undefined}
        placement="bottom-end"
        style={{ width: "250px", zIndex: 9999 }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleAccountClose}>
                <MenuList
                  autoFocusItem={accountOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={{
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                    },
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("teamId");
                      localStorage.removeItem("globalOverrideUser");
                      navigate('/logout')
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon className="color-danger" fontSize="14px" />
                    </ListItemIcon>
                    <ListItemText className="color-danger">
                      Logout
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Drawer>
  );
};
