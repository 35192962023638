import React, { useEffect } from 'react'
import { useState, useMemo } from 'react'
import { Avatar, LinearProgress, CircularProgress } from '@mui/material'
import { Box, Tabs, Tab, Snackbar } from '@mui/material'
import { VideocamOff, Launch, Check } from '@mui/icons-material'
import IconLinkedIn from '../../assets/IconLinkedIn'
import IconBxGlobe from '../../assets/IconBxGlobe'

import TooltipCustom from '../common/TooltipCustom'
import ChipTag from '../common/ChipTag'

import api from '../../api/api'
import axios from 'axios'

var env = String(process.env.REACT_APP_ENV)
var proxyEndpoint = 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var vapiEnvId = '6319e72d-0dc8-4bbc-86cc-09d1701df65d' // prod
if(env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  vapiEnvId = '02cb35d0-dd7d-47d1-893f-8367b96aa265'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  vapiEnvId = '49069a35-62f2-4c72-b3d1-63d59f93a324'
}

export default function CandidateProfile({ compact, candidate, sessionId }) {
  const DEFAULT_SCALE = 4; // based on 4 point scale before dynamic point scales implemented

  const [currentTab, setCurrentTab] = useState(0);

  const [audioRecordingLink, setAudioRecordingLink] = useState('');
  const [videoRecordingLink, setVideoRecordingLink] = useState(null);
  const [session, setSession] = useState({});
  const [currentScorecard, setCurrentScorecard] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([])
  const [requestedHire, setRequestedHire] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const test_session_id = '66cce61910df15ad03023539'
  // tabs index
  const INFO = 0
  const INTERVIEW = 1
  const EDUCATION = compact ? 2 : 1
  const OUTPUTS = compact ? 3 : 2

  useEffect(() => {
    if (candidate.session) {
      setSession(candidate.session)
      setCurrentScorecard(candidate.session.scorecard)

      axios.post(`${proxyEndpoint}/generate_video_recording_link`, { id: session._id })
      .then((response) => {
        setVideoRecordingLink(response.data.url)
        console.log('video', response.data.url)
      })
      .catch((error) => {
        console.error('Error generating video recording link:', error);
      });      
    }
  }, [candidate, session._id])

  const getSession = (sessionId) => {
    return axios.post(proxyEndpoint + '/session', { id: sessionId ? sessionId : test_session_id }) // TODO: add session token auth
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(err => {
      console.error(err);
    });
  }

  const sessionScore = useMemo(() => {
    if (!currentScorecard || currentScorecard.length == 0) return -1;
    const scores = currentScorecard.map((signal) => signal.score);
    const average = array => array.reduce((a, b) => a + b) / array.length;
    return average(scores).toFixed(1);
  }, [currentScorecard])

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const normalizeScore = (score, scale) => {
    if (Number(score) == 0) return 1;
    return (Number(score) * 100 / scale);
  }

  const getColorFromScore = (score, scale) => {
    const range = normalizeScore(score, scale);
    if (range < 50) return '#E02E2E'
    if (range < 75) return '#E27C1E'
    else return '#5C9B0A';
  }

  const getColorBgFromScore = (score, scale) => {
    const range = normalizeScore(score, scale);
    if (range < 50) return '#F8D5D5'
    if (range < 75) return '#FFF7ED'
    else return '#EEF5E6';
  }

  const TextSection = ({title, subheadings, description}) => {
    return (
      <>
        <div className='d-flex flex-row' style={{ width: '100%', marginBottom: '24px' }}>
          {/* Divider */}
          <div className='d-flex flex-col' style={{ width: '20px' }}>
            <span style={{ width: '4px', height: '18px', backgroundColor: 'var(--color-divider)' }}>
            </span>
          </div>
          {/* Heading and Text */}
          <div className='d-flex flex-col' style={{ flex: 1 }}>
            <h5 className='lh-20 section-heading color-heading'>{title}</h5>
            {subheadings.map((subheading, idx) => {
              return <p className={`lh-20 section-heading color-subheading${idx == 0 ? '' : '-light'}`} 
                style={{ lineHeight: '20px', fontSize: '14px', marginBottom: '4px' }}>
                {subheading}
              </p>
            })}
            <p className='m-0 lh-20 color-subheading' style={{ lineHeight: '20px', fontSize: '14px' }}>{description}</p>
          </div>

        </div>
      </>
    )
  }

  
	const renderOutputValue = (value) => {
		if(!Array.isArray(value)){
			return <h5 className='section-heading color-main-light'>{value}</h5>;
		}

		if (value.length === 0) {
			return <p className='section-heading color-gray' style={{ fontSize: '14px' }}>No values found.</p>;
		}

		const formattedItems = value.map(item => {
			if (typeof item === 'string') {
				return `${item}`;
			}
			return String(item);
		});

		// const output = '[\n  ' + formattedItems.join(',\n  ') + '\n]';
    const output = <>
      {formattedItems.map((item) => {
        return <h5 className='section-heading color-main-light'>{item}</h5>
      })}
    </>
		return output;
	}

  const handleShareProfile = () => {
    navigator.clipboard.writeText(window.location.origin + '/candidate/' + candidate._id).then(() => {
      setSnackbarMsg('Candidate share link copied to clipboard successfully')
      setOpenSnackbar(true)
    })
  }

  if (!candidate) return (
    <div>
      <h3>Candidate not found.</h3>
    </div>
  )

  const requestHire = () => {
    setRequestedHire((prev) => !prev);
  }

  return (
    <div className='d-flex flex-col'>
      {/* Header Details */}
      <div className='d-flex flex-row' style={{ padding: compact ? '16px' : '16px 32px', backgroundColor: "white" }}>
        <Avatar
          className="my-auto"
          src={candidate.picture}
          sx={{
            color: "black",
            width: 40,
            height: 40,
            borderRadius: "50%",
            textTransform: "capitalize",
            fontSize: "16px",
            marginRight: "16px",
          }}
        >
          {candidate.picture ? <img
            src={candidate.picture}
            width={40}
            length={40}
          /> : <>
            {candidate.first_name && candidate.first_name.charAt(0)}
            {candidate.last_name && candidate.last_name.charAt(0)}
          </>}
        </Avatar>    
        <div className='d-flex flex-col'>
          <h3 className='section-heading'>{candidate.first_name} {candidate.last_name}</h3>
          <p className='color-gray' style={{ margin: 0, fontSize: '14px' }}>{candidate.best_suited_roles && candidate.best_suited_roles[0]}</p>
          <div className='d-flex flex-row' style={{ marginTop: '8px' }}>
            {candidate.links && candidate.links.length > 0 && candidate.links.map((link) => {
              switch (link.type) {
                case 'linkedin':
                  return <IconLinkedIn className="color-gray clickable" style={{ marginRight: '12px' }} onClick={(e) => {
                    e.preventDefault();
                    window.open('https://' + link.url, '_blank');
                  }}></IconLinkedIn>
                default:
                  return <IconBxGlobe className="color-gray clickable" style={{ marginRight: '12px' }} onClick={(e) => {
                    e.preventDefault();
                    window.open('https://' + link.url, '_blank');
                  }}></IconBxGlobe>
              }
            })}
          </div>
        </div>
        
        <button className="btn-main outlined gray d-flex" 
          style={{ marginLeft: 'auto', marginBottom: 0, padding: '6px 12px', fontWeight: 600, fontSize: '14px' }}
          onClick={handleShareProfile}>
            <Launch style={{ fontSize: '18px', marginRight: '4px' }}></Launch>
            {`Share${compact ? '' : ' Profile'}`}
        </button>
        <button className="btn-main outlined gray d-flex" 
          style={{ marginLeft: '12px', marginBottom: 0, padding: '6px 12px', fontWeight: 600, fontSize: '14px', opacity: requestedHire ? 0.7 : 1 }}
          onClick={requestHire}>
            {requestedHire ? <Check style={{ fontSize: '18px', marginRight: '4px', color: 'var(--color-accept)' }}></Check> : ``}
            {requestedHire ? `Requested Hire` : `Request Hire`}
        </button>
      </div>
      
      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: compact ? 'transparent' : 'divider', background: 'white' }}>
        <Tabs value={currentTab} onChange={handleTabChange} disableRipple
          style={{ padding: compact ? '0px 16px' : '0px 32px', background: 'white', }}
          sx={compact ? {
            ['& .MuiTabs-indicator']: {
              backgroundColor: 'transparent',
            },
            ['& .MuiTab-root']: {
              color: "var(--color-main-light)",
              fontSize: '14px',
              borderRadius: '8px',
              padding: '4px 8px',
              marginRight: '4px',
              fontFamily: 'PPNeueMontreal',
              textTransform: 'none',
              minHeight: '40px'
            },
            ['& .MuiTab-root.Mui-selected']: {
              color: 'var(--color-main)',
              backgroundColor: 'var(--color-lightgray)',
              fontWeight: 600
            },
          } : {}}>
          <Tab label="General Info" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === INFO ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === INFO ? 'black': 'darkgray', zIndex: currentTab === INFO ? 10 : 0 }} />
          {compact && <Tab label="AI Interview" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === INTERVIEW ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === INTERVIEW ? 'black' : 'darkgray', zIndex: currentTab === INTERVIEW ? 10 : 0 }} />}
          <Tab label="Education" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === EDUCATION ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === EDUCATION ? 'black' : 'darkgray', zIndex: currentTab === EDUCATION ? 10 : 0 }} />
          {/* <Tab label="Outputs" disableRipple style={{ textTransform: 'none', fontSize: '14px', fontWeight: currentTab === OUTPUTS ? 600 : 500, fontFamily: 'PPNeueMontreal', color: currentTab === OUTPUTS ? 'black' : 'darkgray', zIndex: currentTab === OUTPUTS ? 10 : 0 }} /> */}
        </Tabs>
      </Box>

      {/* Split Cols */}
      <div className='d-flex flex-row' style={{ width: '100%', height: 'calc(100vh - 236px)', overflowY: 'hidden' }}>
        {/* Left Col */}
        <>
          {/* General Info */}
          {currentTab === INFO && 
          <div className='d-flex flex-col' style={{ flex: 1, padding: compact ? '16px' : '32px', borderRight: compact ? '' : '1px solid lightgray' }}>
            {/* Scorecard Mini*/}
            {currentScorecard && currentScorecard.length != 0 ? <><h5 className="color-main m-0" style={{ marginBottom: '12px' }}>Overall Score</h5>
            <div className="d-flex flex-row section" style={{ backgroundColor: getColorBgFromScore(sessionScore, DEFAULT_SCALE), width: 'auto', padding: '20px', borderRadius: "20px" }}>
              <div className="d-flex" style={{ width: '72px' }}>
                <div className="d-flex my-auto" style={{ backgroundColor: getColorFromScore(sessionScore, DEFAULT_SCALE), height: '48px', width: '48px', borderRadius: "12px", marginRight: 'auto' }}>
                  <h2 className="m-auto" style={{ fontSize: '20px', color: 'white' }}>
                    { sessionScore }
                  </h2>
                </div>
              </div>
              <div className='d-flex flex-col' style={{ flex: 10, width: '100%' }}>
                {currentScorecard.map((scorecardItem, index) => (
                  <div className='d-flex flex-row' style={{ marginBottom: currentScorecard && index < currentScorecard.length - 1 ? '12px' : '' }}>
                  <TooltipCustom
                    title={<>
                        <p className="m-0 color-gray" style={{ fontWeight: 500, fontSize: '12px' }}>
                          {scorecardItem.feedback}
                        </p>
                      </>}
                    style={{ marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                    <h5 className='m-0' style={{ fontSize: '13px', flex: 3, marginRight: '8px' }}>{scorecardItem.signal}</h5>
                  </TooltipCustom>
                    <LinearProgress className="my-auto" variant="determinate" value={normalizeScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE)}
                      style={{ backgroundColor: "#DADADA", height: '4px', borderRadius: '4px', flex: 6 }}
                      sx={{
                        [`& .MuiLinearProgress-bar`]: {
                          backgroundColor: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE),
                        },
                      }} />
                    <h5 className="m-0" style={{ marginLeft: '16px', color: getColorFromScore(scorecardItem.score, scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE) }}>
                      {scorecardItem.score}/{scorecardItem.scale ? scorecardItem.scale : DEFAULT_SCALE}</h5>
                  </div>
                  )
                )}
              </div>
            </div></> : 
            <div className="d-flex flex-row section" style={{ backgroundColor: 'var(--color-lightgray)', width: 'auto', padding: '20px', borderRadius: "20px" }}>
              <div className='d-flex flex-col' style={{ flex: 10, width: '100%' }}>
                <h5 className="m-0" style={{ marginLeft: '16px', color: 'var(--color-main-light)' }}>
                  No session scorecard data available
                </h5>
              </div>
            </div>}

            
            {/* Experience */}
            <h5 className="color-main m-0" style={{ marginBottom: '12px' }}>Last Experience</h5>
            
            {/* Indented Text Section */}
            {candidate.experiences && candidate.experiences.map((experience) => 
              <TextSection title={experience.job_title} subheadings={[experience.employer, experience.dates_employed]} description={experience.description}></TextSection>
            )}
            
          </div>}
          
          {/* Education */}
          {currentTab === EDUCATION && 
          <div className='d-flex flex-col' style={{ flex: 1, padding: compact ? '16px' : '32px', borderRight: compact ? '' : '1px solid lightgray' }}>
            <h5 className="color-main m-0" style={{ marginBottom: '12px' }}>Education</h5>
            
            {/* Indented Text Section */}
            {candidate.education && candidate.education.map((experience) => 
              <TextSection title={experience.institution} subheadings={[experience.education_title, experience.years]} description={experience.description}></TextSection>
            )}
            
          </div>}
          
          {/* Outputs */}
          {currentTab === OUTPUTS && 
          <div className='d-flex flex-col' style={{ flex: 1, padding: compact ? '16px' : '32px', borderRight: compact ? '' : '1px solid lightgray' }}>
            <h5 className="color-main m-0" style={{ marginBottom: '12px' }}>Outputs</h5>
            
            {/* Indented Text Section */}
            {candidate.outputs && candidate.outputs.map((output) => 
              <div className="d-flex flex-col section" style={{ padding: '12px', borderRadius: '12px', border: compact ? '' : '1px solid lightgray' }}>
                {Object.entries(output).map(([key, value]) => (
                  <>
                    <div className="section-sm">
                      <ChipTag variant="default" text={key} style={{ width: 'max-content' }} />
                    </div>
                    <div style={{ marginLeft: '4px' }}>
                      {renderOutputValue(value)}
                    </div>
                  </>
                  )
                )}
              </div>
            )}
            
          </div>}

          {compact && currentTab === INTERVIEW && 
            <div className='d-flex flex-col' style={{ flex: 1, padding: compact ? '16px' : '32px', }}>
              <h5 className="color-main m-0" style={{ marginBottom: '12px' }}>AI Interview</h5>
              
              {/* Video / Audio */}
              <Box className="section">
                    {/* Video / Audio */}
                  <div className="section" style={{ borderRadius: '6px', overflow: 'hidden' }}>
                    {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                      <a className="loom-video" href={session.videoRecordingUrl}></a>
                    }
                    {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                      <>
                        {(videoRecordingLink && videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                          <video
                            width="100%"
                            type="video/mp4"
                            controls
                            preload="auto"
                          >
                            <source src={videoRecordingLink} type="video/mp4" />
                          </video>
                          :
                          <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9' }}>
                            <VideocamOff className="m-auto" style={{ color: '#ACACAC' }}/>
                          </div>
                        }
                      </>
                    }
                  </div>
                {audioRecordingLink && <div className="d-flex flex-col">
                  <h4 className="section-heading">Audio Recording</h4>
                  <audio controls
                    className="audio-player"
                    style={{ background: 'transparent', padding: '4px' }}>
                      <source src={audioRecordingLink} type="audio/mpeg" />
                  </audio>
                </div>}
              </Box>

              {/* Summary */}
              <Box className="section">
                <h4 className="section-heading">Summary</h4>
                <p style={{ fontSize: '14px', margin: 0 }}>
                  {session.summary === undefined ? 'N/A' : session.summary}
                </p>
              </Box>
            </div>}
        </>

        {/* Right Col */}
        <>
          {!compact && <div className='d-flex flex-col' style={{ flex: 1, padding: '32px' }}>
            {/* Video / Audio */}
            <Box className="section">
              <div className="section" style={{ borderRadius: '6px', overflow: 'hidden' }}>
                {(session && session.videoRecordingUrl && session.videoRecordingUrl.includes('loom')) &&
                  <a className="loom-video" href={session.videoRecordingUrl}></a>
                }
                {(session && (!session.videoRecordingUrl || (session.videoRecordingUrl && !session.videoRecordingUrl.includes('loom'))) ) &&
                  <>
                    {(videoRecordingLink && videoRecordingLink.length > 0 && (session.client_type === 'desktop' || session.candidate_metadata && session.candidate_metadata.call_type === 'web')) ?
                      <video
                        width="100%"
                        type="video/mp4"
                        controls
                        preload="auto"
                      >
                        <source src={videoRecordingLink} type="video/mp4" />
                      </video>
                      :
                      <div className="d-flex color-empty-bg" style={{ width: '100%', aspectRatio: '16 / 9' }}>
                        <VideocamOff className="m-auto" style={{ color: '#ACACAC' }}/>
                      </div>
                    }
                  </>
                }
              </div>
              {audioRecordingLink && <div className="d-flex flex-col">
                <h4 className="section-heading">Audio Recording</h4>
                <audio controls
                  className="audio-player"
                  style={{ background: 'transparent', padding: '4px' }}>
                    <source src={audioRecordingLink} type="audio/mpeg" />
                </audio>
              </div>}
            </Box>

            {/* Summary */}
            <Box className="section">
              <h4 className="section-heading">Summary</h4>
              <p style={{ fontSize: '14px', margin: 0 }}>
                {session.summary === undefined ? 'N/A' : session.summary}
              </p>
            </Box>
          </div>}
        </>
      </div>
      
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {setOpenSnackbar(false)}}
        message={snackbarMsg}
      />
    </div>
  )
}
