// example backend item, to be fetched from frontend
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}

export const candidate_data = [
  {
    candidate_id: "6710198d7e6a1f4abb18d90d",
    first_name: "Emilia",
    given_name: "Handerson",
    location: "United States",
    title: "Design lead at Wayfaster",
    salary: "$3,500",
    tags: ["UI/UX Designer", "Full-stack Developer"],
    score: 7.5,
    links: [
      {
        type: "linkedin",
        url: "linkedin.com/thisisemiliahaanderson",
      },
      {
        type: "twitter",
        url: "x.com/emiliahanderson",
      },
      {
        type: "portfolio",
        url: "thisisemiliahanderson.com",
      },
    ],
    profile_picture:
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/99c24a191415535.65cb530ed1b11.jpg",
    experiences: [
      {
        employer: "Wayfaster",
        job_title: "Software Developer Engineer",
        dates_employed: "Apr 2021 - present",
        description: "",
      },
      {
        employer: "Microsoft",
        job_title: "Python Developer",
        dates_employed: "Jun 2020 - Apr 2021",
        description: `At Microsoft I was responsible for:
                - creating innovative softwares
                - developing systems
                - processing large amount of data`,
      },
      {
        employer: "Google",
        job_title: "Junior Backend Developer",
        dates_employed: "Feb 2018 - Jun 2020",
        description: "",
      },
    ],
    education: [
      {
        institution: "Massachusetts Institute of Technology",
        education_title: "BS in Engineering",
        dates_attended: "2012 - 2015",
      },
    ],
    // company-side items
    interviews: [
      {
        interview_title: "Design System Lead",
        overall_score: "3.5",
        scorecard: [
          {
            asked_to_candidate: true,
            feedback:
              "Candidate expressed a solutions-oriented approach towards unpredictable shift requirements and emphasized positive customer service, demonstrating a proactive and positive attitude.",
            score: 4,
            signal: "Attitude",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Though the candidate identified correct attire and the importance of timely communication with supervisors, there was no direct evidence of understanding professional behavior under pressure or managing personal phone use.",
            score: 3,
            signal: "Professionalism",
          },
          {
            asked_to_candidate: true,
            feedback:
              "The candidate confirmed they have a reliable transportation plan and are aware of attire requirements but did not demonstrate advance preparation for shifts beyond these aspects.",
            score: 3,
            signal: "Shift Readiness",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Acknowledged the importance of notifying supervisors in cases of delays and offered to take on additional tasks if finishing early, suggesting a generally reliable approach.",
            score: 4,
            signal: "Reliability",
          },
        ],
        interview_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.mp4",
        audio_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "James",
    given_name: "Anderson",
    location: "United States",
    title: "Design lead at Netuguru",
    salary: "$3,500",
    tags: ["UI/UX Designer", "Full-stack Developer"],
    score: 7.5,
    links: [
      {
        type: "linkedin",
        url: "linkedin.com/thisisemiliahaanderson",
      },
      {
        type: "twitter",
        url: "x.com/emiliahanderson",
      },
      {
        type: "portfolio",
        url: "thisisemiliahanderson.com",
      },
    ],
    profile_picture:
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/99c24a191415535.65cb530ed1b11.jpg",
    experiences: [
      {
        employer: "Wayfaster",
        job_title: "Software Developer Engineer",
        dates_employed: "Apr 2021 - present",
        description: "",
      },
      {
        employer: "Microsoft",
        job_title: "Python Developer",
        dates_employed: "Jun 2020 - Apr 2021",
        description: `At Microsoft I was responsible for:
                - creating innovative softwares
                - developing systems
                - processing large amount of data`,
      },
      {
        employer: "Google",
        job_title: "Junior Backend Developer",
        dates_employed: "Feb 2018 - Jun 2020",
        description: "",
      },
    ],
    education: [
      {
        institution: "Massachusetts Institute of Technology",
        education_title: "BS in Engineering",
        dates_attended: "2012 - 2015",
      },
    ],
    // company-side items
    interviews: [
      {
        interview_title: "Design System Lead",
        overall_score: "3.5",
        scorecard: [
          {
            asked_to_candidate: true,
            feedback:
              "Candidate expressed a solutions-oriented approach towards unpredictable shift requirements and emphasized positive customer service, demonstrating a proactive and positive attitude.",
            score: 4,
            signal: "Attitude",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Though the candidate identified correct attire and the importance of timely communication with supervisors, there was no direct evidence of understanding professional behavior under pressure or managing personal phone use.",
            score: 3,
            signal: "Professionalism",
          },
          {
            asked_to_candidate: true,
            feedback:
              "The candidate confirmed they have a reliable transportation plan and are aware of attire requirements but did not demonstrate advance preparation for shifts beyond these aspects.",
            score: 3,
            signal: "Shift Readiness",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Acknowledged the importance of notifying supervisors in cases of delays and offered to take on additional tasks if finishing early, suggesting a generally reliable approach.",
            score: 4,
            signal: "Reliability",
          },
        ],
        interview_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.mp4",
        audio_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Sydney",
    given_name: "Worthy",
    location: "Portugal",
    title: "Software Engineer at Figma",
    salary: "$3,500",
    tags: ["UI/UX Designer", "Full-stack Developer"],
    score: 7.5,
    links: [
      {
        type: "linkedin",
        url: "linkedin.com/thisisemiliahaanderson",
      },
      {
        type: "twitter",
        url: "x.com/emiliahanderson",
      },
      {
        type: "portfolio",
        url: "thisisemiliahanderson.com",
      },
    ],
    profile_picture:
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/99c24a191415535.65cb530ed1b11.jpg",
    experiences: [
      {
        employer: "Wayfaster",
        job_title: "Software Developer Engineer",
        dates_employed: "Apr 2021 - present",
        description: "",
      },
      {
        employer: "Microsoft",
        job_title: "Python Developer",
        dates_employed: "Jun 2020 - Apr 2021",
        description: `At Microsoft I was responsible for:
                - creating innovative softwares
                - developing systems
                - processing large amount of data`,
      },
      {
        employer: "Google",
        job_title: "Junior Backend Developer",
        dates_employed: "Feb 2018 - Jun 2020",
        description: "",
      },
    ],
    education: [
      {
        institution: "Massachusetts Institute of Technology",
        education_title: "BS in Engineering",
        dates_attended: "2012 - 2015",
      },
    ],
    // company-side items
    interviews: [
      {
        interview_title: "Design System Lead",
        overall_score: "3.5",
        scorecard: [
          {
            asked_to_candidate: true,
            feedback:
              "Candidate expressed a solutions-oriented approach towards unpredictable shift requirements and emphasized positive customer service, demonstrating a proactive and positive attitude.",
            score: 4,
            signal: "Attitude",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Though the candidate identified correct attire and the importance of timely communication with supervisors, there was no direct evidence of understanding professional behavior under pressure or managing personal phone use.",
            score: 3,
            signal: "Professionalism",
          },
          {
            asked_to_candidate: true,
            feedback:
              "The candidate confirmed they have a reliable transportation plan and are aware of attire requirements but did not demonstrate advance preparation for shifts beyond these aspects.",
            score: 3,
            signal: "Shift Readiness",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Acknowledged the importance of notifying supervisors in cases of delays and offered to take on additional tasks if finishing early, suggesting a generally reliable approach.",
            score: 4,
            signal: "Reliability",
          },
        ],
        interview_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.mp4",
        audio_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Zara",
    given_name: "Bailey",
    location: "United States",
    title: "Software Engineer at Google",
    salary: "$6,000",
    tags: ["UI/UX Designer", "Full-stack Developer"],
    score: 7.5,
    links: [
      {
        type: "linkedin",
        url: "linkedin.com/thisisemiliahaanderson",
      },
      {
        type: "twitter",
        url: "x.com/emiliahanderson",
      },
      {
        type: "portfolio",
        url: "thisisemiliahanderson.com",
      },
    ],
    profile_picture:
      "https://mir-s3-cdn-cf.behance.net/project_modules/disp/99c24a191415535.65cb530ed1b11.jpg",
    experiences: [
      {
        employer: "Wayfaster",
        job_title: "Software Developer Engineer",
        dates_employed: "Apr 2021 - present",
        description: "",
      },
      {
        employer: "Microsoft",
        job_title: "Python Developer",
        dates_employed: "Jun 2020 - Apr 2021",
        description: `At Microsoft I was responsible for:
                - creating innovative softwares
                - developing systems
                - processing large amount of data`,
      },
      {
        employer: "Google",
        job_title: "Junior Backend Developer",
        dates_employed: "Feb 2018 - Jun 2020",
        description: "",
      },
    ],
    education: [
      {
        institution: "Massachusetts Institute of Technology",
        education_title: "BS in Engineering",
        dates_attended: "2012 - 2015",
      },
    ],
    // company-side items
    interviews: [
      {
        interview_title: "Design System Lead",
        overall_score: "3.5",
        scorecard: [
          {
            asked_to_candidate: true,
            feedback:
              "Candidate expressed a solutions-oriented approach towards unpredictable shift requirements and emphasized positive customer service, demonstrating a proactive and positive attitude.",
            score: 4,
            signal: "Attitude",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Though the candidate identified correct attire and the importance of timely communication with supervisors, there was no direct evidence of understanding professional behavior under pressure or managing personal phone use.",
            score: 3,
            signal: "Professionalism",
          },
          {
            asked_to_candidate: true,
            feedback:
              "The candidate confirmed they have a reliable transportation plan and are aware of attire requirements but did not demonstrate advance preparation for shifts beyond these aspects.",
            score: 3,
            signal: "Shift Readiness",
          },
          {
            asked_to_candidate: true,
            feedback:
              "Acknowledged the importance of notifying supervisors in cases of delays and offered to take on additional tasks if finishing early, suggesting a generally reliable approach.",
            score: 4,
            signal: "Reliability",
          },
        ],
        interview_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.mp4",
        audio_recording:
          "https://s3.amazonaws.com/bucket/interviews/abcdefg.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Liam",
    given_name: "Baker",
    location: "United Kingdom",
    title: "Lead Software Engineer at Innotech",
    salary: "$5,000",
    tags: ["Backend Developer", "Cloud Specialist"],
    score: 8.3,
    links: [
      { type: "linkedin", url: "linkedin.com/in/liambaker" },
      { type: "github", url: "github.com/liambakercode" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/men/5.jpg",
    experiences: [
      { employer: "Innotech", job_title: "Lead Software Engineer", dates_employed: "Jul 2018 - present", description: "Responsible for leading backend development for cloud solutions." },
      { employer: "SoftServe", job_title: "Cloud Engineer", dates_employed: "Jan 2016 - Jun 2018", description: "Managed and optimized cloud infrastructure for high availability." },
    ],
    education: [{ institution: "University of Oxford", education_title: "MSc in Computer Science", dates_attended: "2012 - 2015" }],
    interviews: [
      {
        interview_title: "Senior Cloud Architect",
        overall_score: "4.0",
        scorecard: [
          { asked_to_candidate: true, feedback: "Showed strong expertise in cloud architecture and adaptability.", score: 4, signal: "Technical Knowledge" },
          { asked_to_candidate: true, feedback: "Demonstrated leadership in team projects and adaptability under challenging conditions.", score: 4, signal: "Teamwork" },
          { asked_to_candidate: true, feedback: "Showed deep understanding of cloud-based disaster recovery.", score: 4, signal: "Problem Solving" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/xyzabc123.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/xyzabc123.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Mia",
    given_name: "Wong",
    location: "Canada",
    title: "Data Analyst at BigInsights",
    salary: "$4,200",
    tags: ["Data Analysis", "Machine Learning"],
    score: 7.9,
    links: [
      { type: "linkedin", url: "linkedin.com/in/miawong" },
      { type: "personal", url: "miawong.com" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/women/7.jpg",
    experiences: [
      { employer: "BigInsights", job_title: "Data Analyst", dates_employed: "May 2019 - present", description: "Specialized in large-scale data analysis and reporting." },
      { employer: "DataGenix", job_title: "Data Science Intern", dates_employed: "Jun 2018 - Apr 2019", description: "Assisted in building machine learning models for predictive analysis." },
    ],
    education: [{ institution: "University of Toronto", education_title: "BSc in Data Science", dates_attended: "2014 - 2018" }],
    interviews: [
      {
        interview_title: "Data Science Lead",
        overall_score: "3.8",
        scorecard: [
          { asked_to_candidate: true, feedback: "Excellent grasp on machine learning algorithms and data preprocessing.", score: 4, signal: "Technical Expertise" },
          { asked_to_candidate: true, feedback: "Exhibited strong logical thinking for data-driven decision-making.", score: 4, signal: "Analytical Skills" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/miawong.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/miawong.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Ava",
    given_name: "Robinson",
    location: "United States",
    title: "Project Manager at TechNova",
    salary: "$4,500",
    tags: ["Project Management", "Scrum Master"],
    score: 8.2,
    links: [
      { type: "linkedin", url: "linkedin.com/in/avarobinson" },
      { type: "portfolio", url: "avarobinson.com" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/women/10.jpg",
    experiences: [
      { employer: "TechNova", job_title: "Project Manager", dates_employed: "Feb 2020 - present", description: "Managed cross-functional teams to deliver projects on time and within scope." },
      { employer: "InnovateX", job_title: "Project Coordinator", dates_employed: "Jan 2018 - Jan 2020", description: "Assisted with project planning and tracked project deliverables." },
    ],
    education: [{ institution: "University of California", education_title: "BA in Business Administration", dates_attended: "2013 - 2017" }],
    interviews: [
      {
        interview_title: "Senior Project Manager",
        overall_score: "3.9",
        scorecard: [
          { asked_to_candidate: true, feedback: "Showed strong understanding of Agile methodology and project planning.", score: 4, signal: "Project Management" },
          { asked_to_candidate: true, feedback: "Demonstrated excellent communication skills in managing stakeholders.", score: 4, signal: "Communication" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/avarobinson.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/avarobinson.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Noah",
    given_name: "Patel",
    location: "India",
    title: "Data Scientist at DataEdge",
    salary: "$5,300",
    tags: ["Data Science", "Python", "Machine Learning"],
    score: 8.5,
    links: [
      { type: "linkedin", url: "linkedin.com/in/noahpatel" },
      { type: "github", url: "github.com/noahpatel" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/men/15.jpg",
    experiences: [
      { employer: "DataEdge", job_title: "Data Scientist", dates_employed: "Mar 2019 - present", description: "Developed predictive models and data visualizations for client projects." },
      { employer: "DeepDive Analytics", job_title: "Data Analyst", dates_employed: "May 2017 - Feb 2019", description: "Conducted data cleaning and statistical analysis for research projects." },
    ],
    education: [{ institution: "Indian Institute of Technology", education_title: "MSc in Data Science", dates_attended: "2012 - 2016" }],
    interviews: [
      {
        interview_title: "Lead Data Scientist",
        overall_score: "4.1",
        scorecard: [
          { asked_to_candidate: true, feedback: "Possessed extensive knowledge in machine learning and Python.", score: 3, signal: "Technical Skill" },
          { asked_to_candidate: true, feedback: "Demonstrated critical thinking and problem-solving abilities.", score: 4, signal: "Analytical Ability" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/noahpatel.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/noahpatel.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Sophia",
    given_name: "Garcia",
    location: "United Kingdom",
    title: "Marketing Specialist at BlueSky",
    salary: "$3,800",
    tags: ["Marketing", "SEO", "Content Creation"],
    score: 7.3,
    links: [
      { type: "linkedin", url: "linkedin.com/in/sophiagarcia" },
      { type: "portfolio", url: "sophiagarcia.com" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/women/22.jpg",
    experiences: [
      { employer: "BlueSky", job_title: "Marketing Specialist", dates_employed: "Sep 2019 - present", description: "Implemented SEO strategies and managed content creation for campaigns." },
      { employer: "BrightMedia", job_title: "Content Writer", dates_employed: "Jan 2017 - Aug 2019", description: "Created and optimized content for blog and social media platforms." },
    ],
    education: [{ institution: "University of London", education_title: "BA in Marketing", dates_attended: "2012 - 2016" }],
    interviews: [
      {
        interview_title: "Senior Marketing Specialist",
        overall_score: "3.7",
        scorecard: [
          { asked_to_candidate: true, feedback: "Proven expertise in digital marketing and SEO.", score: 4, signal: "Marketing Knowledge" },
          { asked_to_candidate: true, feedback: "Articulated clear and effective content strategies.", score: 3, signal: "Content Strategy" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/sophiagarcia.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/sophiagarcia.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Ethan",
    given_name: "Kim",
    location: "South Korea",
    title: "Frontend Developer at CodeWorks",
    salary: "$4,200",
    tags: ["JavaScript", "React", "CSS"],
    score: 8.0,
    links: [
      { type: "linkedin", url: "linkedin.com/in/ethankim" },
      { type: "github", url: "github.com/ethankim" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/men/31.jpg",
    experiences: [
      { employer: "CodeWorks", job_title: "Frontend Developer", dates_employed: "Nov 2019 - present", description: "Focused on UI development using React and modern JavaScript frameworks." },
      { employer: "TechSoft", job_title: "Web Developer", dates_employed: "Jul 2017 - Oct 2019", description: "Developed responsive websites using HTML, CSS, and JavaScript." },
    ],
    education: [{ institution: "Seoul National University", education_title: "BSc in Computer Science", dates_attended: "2013 - 2017" }],
    interviews: [
      {
        interview_title: "Lead Frontend Developer",
        overall_score: "4.2",
        scorecard: [
          { asked_to_candidate: true, feedback: "Decent skills in React and front-end development.", score: 3, signal: "Technical Skill" },
          { asked_to_candidate: true, feedback: "Effective in collaborating with design and backend teams.", score: 4, signal: "Collaboration" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/ethankim.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/ethankim.wav",
      },
    ],
  },
  {
    candidate_id: uuidv4(),
    first_name: "Olivia",
    given_name: "Lopez",
    location: "Brazil",
    title: "Full-Stack Developer at WebWise",
    salary: "$5,500",
    tags: ["JavaScript", "Node.js", "React"],
    score: 7.8,
    links: [
      { type: "linkedin", url: "linkedin.com/in/olivialopez" },
      { type: "github", url: "github.com/olivialopez" },
    ],
    profile_picture: "https://randomuser.me/api/portraits/women/45.jpg",
    experiences: [
      { employer: "WebWise", job_title: "Full-Stack Developer", dates_employed: "Oct 2020 - present", description: "Developed and maintained scalable web applications." },
      { employer: "CodeCraft", job_title: "Frontend Developer", dates_employed: "Mar 2018 - Sep 2020", description: "Collaborated with backend teams to build responsive user interfaces." },
    ],
    education: [{ institution: "University of São Paulo", education_title: "BSc in Information Systems", dates_attended: "2014 - 2018" }],
    interviews: [
      {
        interview_title: "Senior Full-Stack Developer",
        overall_score: "3.9",
        scorecard: [
          { asked_to_candidate: true, feedback: "Displayed solid knowledge in JavaScript frameworks and backend integration.", score: 4, signal: "Technical Skill" },
          { asked_to_candidate: true, feedback: "Handled project requirements effectively and collaborated with team members.", score: 4, signal: "Teamwork" },
        ],
        interview_recording: "https://s3.amazonaws.com/bucket/interviews/olivialopez.mp4",
        audio_recording: "https://s3.amazonaws.com/bucket/interviews/olivialopez.wav",
      },
    ],
  },
];
