import React from 'react'
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TooltipCustom from './TooltipCustom';

const FormField = styled(({ className, label, noLabel, noBorder, required, tooltip, tooltipTitle, height, icon, ...props }) => (
  <>
    {!noLabel && <h5 className="input-label d-flex">
      {label}{required ? <span className="color-required"> *</span> : ''}
      {tooltip ? <>
        <TooltipCustom 
          title={tooltipTitle}
          style={{ color: "var(--color-gray)", marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto', fontSize: '14px' }}>
        </TooltipCustom>
      </> : ''}
    </h5>}
    <TextField
      {...props}
      variant="standard"
      fullWidth={true}
      InputProps={{
        disableUnderline: true,
        startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : '',
        style: { padding: '12px 16px', marginBottom: '16px', height: height ? `${height}` : '',
          border: noBorder ? 'none' : '1px solid lightgray', borderRadius: '6px', fontSize: '14px' }
      }}
      sx={{
        ['& .MuiInput-input'] : {
          padding: 0,
        }
      }}
    />
  </>
))({});

export default FormField