import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate, Link } from 'react-router-dom'

import api from '../../api/api'

import { CircularProgress } from '@mui/material'

import InterviewDetail from '../interview/InterviewDetail'

function InterviewDetailList() {
  const navigate = useNavigate()
  const { user, isLoading } = useAuth0();

  const [interviews, setInterviews] = useState([])
  const [getInterviewsCalled, setGetInterviewsCalled] = useState(false)

  useEffect(() => {
    if(!isLoading && user.email !== '' && !getInterviewsCalled) {
      var userId = user.email
      const overrideUserLocal = localStorage.getItem('globalOverrideUser')
      if(overrideUserLocal != null) {
        userId = overrideUserLocal
      }
      const teamId = localStorage.getItem('teamId')
      if(!teamId) {
        navigate('/')
        return
      }

      api.getInterviews(userId, teamId)
      .then((fetchedInterviews) => {
        if(fetchedInterviews) {
          setInterviews(fetchedInterviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))             
          setGetInterviewsCalled(true)
          if(fetchedInterviews.length > 0) {
            navigate('/interviews/' + fetchedInterviews[0]._id)
          }
        } else {
          navigate('/interviews')
        }
      })
      .catch((err) => {
        console.log(err)
        setGetInterviewsCalled(true)
      })
    }
  }, [isLoading, user, interviews, getInterviewsCalled, navigate]);

  return (
    <div>
      {(getInterviewsCalled && interviews.length > 0) &&
        <InterviewDetail importedInterviews={interviews} />
      }
      {(getInterviewsCalled && interviews.length === 0) &&
        <p style={{ textAlign: 'center'}}>No interviews found. Create your first interview <Link to="/create-interview">here.</Link></p>
      }
      {!getInterviewsCalled &&
      <>
        <CircularProgress sx={{ color: 'var(--color-primary)', margin: 'auto' }}/>
      </>
      }
    </div>
  )
}

export default InterviewDetailList