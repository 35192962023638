import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import WayfasterLogo from "../wayfaster-logo.svg";

import PhoneIcon from '@mui/icons-material/Phone';

import api from '../api/api';


function CallToApply() {
  const [headline, setHeadline] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); 
  const [branding, setBranding] = useState('');

  const { interview_id } = useParams();

  useEffect(() => {
    api.getCallToApplyInfo({ interviewId: interview_id }).then((res) => { 
      setHeadline(res.data.outbound.headline);
      setMessage(res.data.outbound.message);
      setPhoneNumber(res.data.phone_number);
      setBranding(res.data.outbound.branding); // branding url
    });
  }, [interview_id]);


  return (
    <div className='d-flex flex-col' style={{ width: '100%', height: '100%', color: 'var(--color-main)' }}>
      <div className='d-flex flex-col m-auto' style={{ padding: '24px', alignItems: 'center'}}>
        {branding && <img
            src={branding}
            alt="Branding"
            className="m-auto"
            style={{
              width: "60px",
              height: "auto",
              marginBottom: '16px',
              objectFit: "contain",
            }}
          />}
        <h3 className='font-inter mx-auto' style={{ fontSize: '20px', margin: 0, marginBottom: '8px', lineHeight: '28px', width: 'fit-content', textAlign: 'center' }}>{headline}</h3>
        <p className='font-inter color-gray mx-auto' style={{ fontSize: '14px', margin: 0, marginBottom: '24px', lineHeight: '24px', width: 'fit-content', textAlign: 'center' }}>{message}</p>
        <div className='d-flex flex-row mx-auto' style={{ padding: '16px', margin: 0, background: 'var(--color-lightgray)', borderRadius: '12px' }}>
          <PhoneIcon className='my-auto' style={{ marginRight: '8px' }}/>
          <p className='font-inter' style={{ fontSize: '20px', lineHeight: '28px', margin: 0, fontWeight: '600' }}>
            {phoneNumber}
          </p>
        </div>
      </div>

      {/* Wayfaster branding */}
      <div className='d-flex flex-row' style={{ marginTop: 'auto', width: '100%', height: '40px', backgroundColor: 'var(--color-lightgray)', padding: '8px' }}>
        <div className='d-flex flex-row mx-auto' style={{ opacity: '0.6' }}>
          <span className='font-inter my-auto' style={{ fontSize: '12px', lineHeight: '24px', marginRight: '6px' }}>Powered by </span>
          <img className='my-auto' alt="Wayfaster" src={WayfasterLogo} style={{ height: '18px' }} />
        </div>
      </div>
    </div>
  )
}

export default CallToApply