var env = String(process.env.REACT_APP_ENV)
const proxyEndpoint = env === 'development' ? 'http://localhost:5555' : env === 'staging' ? 'https://staging-proxy-6f8d86796ff2.herokuapp.com' : 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
const wsEndpoint = env === 'development' ? 'ws://localhost:5555' : env === 'staging' ? 'wss://staging-proxy-6f8d86796ff2.herokuapp.com' : 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'

const SECTIONS_OPTIONS = ['Introduction', 'Background']

const SessionStatus = {
  NOT_STARTED: 1,
  INCOMPLETE: 2,
  COMPLETED: 3,
  IN_PROGRESS: 4
}

const SessionStatusString = {
  [SessionStatus.NOT_STARTED]: 'Not Started',
  [SessionStatus.INCOMPLETE]: 'Incomplete',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.IN_PROGRESS]: 'In Progress'
}

export { proxyEndpoint, wsEndpoint, SECTIONS_OPTIONS, SessionStatus, SessionStatusString }